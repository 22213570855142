import React from 'react';
import { ChemistryImage } from '@/shared/components';
import { Img } from '@/shared/components/sanitizedTags';

/**
 * The chemistry image that's rendered may come from:
 * 1. The passed-in props - although they are immediately copied to state, since when you edit a chemistry image the props
 *    won't change. Instead, after the image is edited we handle the 'updatePageStructureElements' event by updating state.
 * 2. The value of the local variable 'persistStructure', which is used to preserve the structure when the data source
 *    (selected projects) changes.
 */

type Props = {
  src: string;
  srcElementId?: string;
  frontendRenderOnly?: boolean;
  isRegistration?: boolean;
  isSearch?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  assignProperties?: (props: Partial<Props>) => void;
}

/**
 * The actual structure used when editing is typically stored in the hidden <input id='structure'> field.
 * Update this field so that the editor will edit the structure that's displayed.
 */
function updateStructureForEditing(props: Props, label = '') { // eslint-disable-line @typescript-eslint/no-unused-vars
  const { srcElementId, src } = props;
  if (srcElementId) {
    const elem = document.getElementById(srcElementId) as HTMLInputElement;
    if (elem) {
      elem.value = src;
    }
  }
}

export class LaunchStructureEditor extends React.Component<Props> {
  // a list of disposers to be called when the component has remounted to remove old event handlers.
  disposers: Array<() => void> = [];
  ref = React.createRef<HTMLDivElement>();

  /**
   * Handle the event dispatched after the structure has been edited.
   *
   * IMPORTANT: this event is only dispatched when the structure editor is opened with openMarvin4JS(), not with
   * openMarvin4JSWithPromise(), and so it's only hit when editing structures on the search page (not Edit Custom
   * Amino Acids and Nucleotides).
   *
   * @param e An event containing a detail object with the updated structure
   */
  handleUpdateStructureEvent = (e: CustomEvent & { detail: Props }) => {
    const { src } = e.detail;
    this.props.assignProperties?.({ src });
  };

  get isChildOfHiddenFormContent() {
    if (this.ref.current) {
      // eslint-disable-next-line no-jquery/no-parents, no-jquery/no-jquery-constructor
      if ($(this.ref.current).parents('.hidden_form_content').length) {
        return true;
      }
    }
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src) {
      updateStructureForEditing(this.props, 'componentDidUpdate');
    }
  }

  componentDidMount() {
    if (!this.isChildOfHiddenFormContent) {
      window.addEventListener('updatePageStructureElements', this.handleUpdateStructureEvent);
      this.disposers.push(() => {
        window.removeEventListener('updatePageStructureElements', this.handleUpdateStructureEvent);
      });

      setTimeout(() => {
        // Update the value of #structure so that when you click to edit, you'll get the same structure that's displayed.
        // We don't update this immediately, because apparently there's a race condition. That is, some code somewhere is
        // overwriting the value of #structure after we're mounted.
        updateStructureForEditing(this.props);
      });
    }
  }

  componentWillUnmount() {
    this.disposers.forEach(dispose => dispose());
  }

  renderNoStructure() {
    return <div className='content_without_structure' id='no_structure_query'>
      {/* can't use the <Img tag or the svg doesn't pick up the styling */}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
        <defs></defs>
        <g id='MOLECULEICON'>
          <g>
            <path className='structure_editor_icon'
              d='M2.2,24c0-3,0-5.6,0-7.9c0-1,0-2,0-2.9c0-1,0-1.8,0-2.6s0-1.4,0-1.8c0-0.5,0-0.7,0-0.7s0.2-0.1,0.6-0.3 c0.4-0.2,0.9-0.5,1.6-0.9C5,6.4,5.7,6,6.5,5.5C7.4,5,8.2,4.5,9.1,4c2-1.2,4.3-2.5,6.9-4l13.8,8V24l-13.8,8L2.2,24z M16,29.4 l11.6-6.7V9.3L16,2.6l-5.8,3.3C9.4,6.4,8.7,6.8,8,7.2S6.7,7.9,6.2,8.2C5.6,8.5,5.2,8.8,4.9,9C4.6,9.2,4.4,9.3,4.4,9.3 c0,0.8,0,1.5,0,2.1c0,0.6,0,1.3,0,2.2c0,0.8,0,1.6,0,2.5c0,2,0,4.2,0,6.7L16,29.4z M16,5.5c2.8,0,5.3,1,7.3,3.1 c2.1,2.1,3.1,4.5,3.1,7.3c0,2.9-1,5.4-3,7.4c-2,2-4.5,3.1-7.4,3.1c-2.9,0-5.4-1-7.4-3c-2-2-3-4.5-3-7.4c0-2.8,1-5.3,3.1-7.3 C10.7,6.5,13.1,5.5,16,5.5z M15.9,7.3c-2.4,0-4.4,0.8-6.1,2.5c-1.7,1.7-2.5,3.7-2.5,6.1c0,2.4,0.8,4.4,2.5,6.1 c1.7,1.7,3.7,2.5,6.2,2.5c2.4,0,4.4-0.8,6-2.5c1.7-1.7,2.5-3.7,2.5-6c0-2.4-0.8-4.4-2.5-6.1C20.3,8.2,18.3,7.3,15.9,7.3z'></path>
          </g>
        </g>
      </svg>
      <div className='instructions'>
        <h4>Launch the Structure Editor</h4>
      </div>
    </div>;
  }

  renderWithStructure() {
    const { src, isRegistration } = this.props;

    // after displaying the structure, make sure it's shoved into the hidden DOM element so the same structure is used
    // for editing.
    return <ChemistryImage
      src={src}
      id='structure_query'
      width={250}
      height={250}
      isRegistration={isRegistration}
    />;
  }

  renderStructureImageTag() {
    return <Img id='structure_query'
      hidden={true}
      src={null}
      className={'hidden'}></Img>;
  }

  render() {
    if (this.isChildOfHiddenFormContent) {
      return <span />;
    }
    const { src, onClick } = this.props;
    const content = src ? this.renderWithStructure() : this.renderNoStructure();

    // use the data-has-structure to test whether the structure editor has a structure
    return (
      <div className='LaunchStructureEditor' data-src={src} data-has-structure={src ? 'true' : 'false'} ref={this.ref}>
        <div className={'clickable'} onClick={onClick}>
          {content}
          {this.renderStructureImageTag()}
        </div>
      </div>
    );
  }
}
