import React, { useCallback, useEffect, useRef } from 'react';
import { AnyObject } from '@/types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, DialogActions, Tooltip, Typography } from '@mui/material';
import omit from 'lodash/omit';

type ButtonProps = React.ComponentProps<typeof Button>;
type TooltipProps = React.ComponentProps<typeof Tooltip>;
export type ButtonWithTooltipProps = ButtonProps & { tooltip?: string | JSX.Element; tooltipProps?: TooltipProps | AnyObject };

export const ButtonWithTooltip = (props: ButtonWithTooltipProps) => {
  const button = <Button {...(omit(props, 'tooltip', 'tooltipProps'))} />;
  const hasTooltip = !!props.tooltip;
  const contents = (hasTooltip && props.disabled) ? <Typography>{button}</Typography> : button;

  return hasTooltip
    ? (
      <Tooltip title={props.tooltip} {...props.tooltipProps}>
        {contents}
      </Tooltip>
      )
    : contents;
};

export const CancelDialogButton = (props: ButtonWithTooltipProps) => {
  return <ButtonWithTooltip
    {...props}
    variant='text'>
    Cancel
  </ButtonWithTooltip>;
};

export const DeleteDialogButton = (props: ButtonWithTooltipProps) => {
  const label = <><DeleteOutlineIcon sx={{ marginRight: 2 }} />Delete</>;

  return <ButtonWithTooltip
    {...props}
    className={props.disabled ? 'disabled-delete' : ''}
    color='error'
    variant='outlined'>
    {label}
  </ButtonWithTooltip>;
};

type DefaultDialogButtonProps = ButtonWithTooltipProps & { label?: string };
export const DefaultDialogButton = (props: DefaultDialogButtonProps) => {
  return <ButtonWithTooltip
    variant='contained'
    {...props}>
    {props.label ?? 'Save'}
  </ButtonWithTooltip>;
};

type StandardDialogActionsProps = {
  className?: string;
  deleteProps?: ButtonWithTooltipProps;
  cancelProps?: ButtonWithTooltipProps;
  defaultButtonProps?: DefaultDialogButtonProps;
  children?: React.ReactNode;
};

/**
 * Test to see if an element is inside the topmost presentation element. Used to determine if a dialog
 * (or element within a dialog) is inside the active modal layer.
 * @param element - The element to test.
 * @returns true if the element is inside the topmost presentation element, false otherwise.
 */
export const isElementInTopmostModalLayer = (element: HTMLElement) => {
  const topmostPresentationElements = document.querySelectorAll('[role="presentation"]');
  if (topmostPresentationElements.length === 0) {
    // if this there are no presentation elements on screen, then the element is in the topmost layout
    return true;
  }
  const topmostPresentationElement = topmostPresentationElements[topmostPresentationElements.length - 1];
  return topmostPresentationElement && topmostPresentationElement.contains(element);
};

/**
 * A wrapper for DialogActions that may include a delete button, a cancel button, and a default button, as well as optional
 * children which appear to the right of the delete button and to the left of the cancel button.
 */
export const StandardDialogActions: React.FC<StandardDialogActionsProps> =
  ({ deleteProps, cancelProps, defaultButtonProps, children, className }) => {
    const dialogRef = useRef<HTMLDivElement>(null);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (!isElementInTopmostModalLayer(dialogRef.current)) {
          return;
        }

        if (event.key === 'Enter' && defaultButtonProps?.onClick) {
          event.preventDefault();
          event.stopPropagation();
          defaultButtonProps.onClick(null);
        }
        if (event.key === 'Escape' && cancelProps?.onClick) {
          event.preventDefault();
          event.stopPropagation();
          cancelProps.onClick(null);
        }
      },
      [defaultButtonProps, cancelProps],
    );

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const buttonStyle: React.CSSProperties = { margin: '0.5rem' };
    return <DialogActions ref={dialogRef} className={`StandardDialogActions bottom-bar ${className ?? ''}`}>
      {deleteProps && <DeleteDialogButton {...deleteProps} style={buttonStyle} />}
      <div style={{ flex: 1 }}>
        {children}
      </div>
      {cancelProps && <CancelDialogButton {...cancelProps} style={buttonStyle} />}
      {defaultButtonProps && <DefaultDialogButton {...defaultButtonProps} style={buttonStyle} />}
    </DialogActions>;
  };
