import React from 'react'

import icons from '@/shared/utils/icons.js'
import { Img } from './sanitizedTags.js'

export default class Icon extends React.Component {
  render() {
    const { icon, forceSize, onClick, style } = this.props

    return (
      <Img
        src={icons[icon]}
        className={'icon-16 ' + icon}
        height={forceSize}
        width={forceSize}
        alt={icon}
        onClick={onClick}
        style={style}
      />
    )
  }
}
