import { NodeLocation, NodeLocationType, RenderMoleculeSVG } from '@cdd/ui-kit/lib/molRendering/v2/RenderMoleculeSVG';
import { RenderingNode } from '@cdd/ui-kit/lib/molRendering/v2/V3000Layout';
import { AtomProps, TemplateDefn } from '@cdd/ui-kit/lib/molRendering/v2/V3000Template';
import { OutlineMeasurement } from 'webmolkit/gfx/ArrangeMeasurement';
import { ArrangeMolecule } from 'webmolkit/gfx/ArrangeMolecule';
import { DrawMolecule } from 'webmolkit/gfx/DrawMolecule';
import { FontData } from 'webmolkit/gfx/FontData';
import { MetaVector, TextAlign } from 'webmolkit/gfx/MetaVector';
import { RenderEffects, RenderPolicy } from 'webmolkit/gfx/Rendering';
import { Molecule } from 'webmolkit/mol/Molecule';
import { MolUtil } from 'webmolkit/mol/MolUtil';
import { GeomUtil } from 'webmolkit/util/Geom';
import { Vec } from 'webmolkit/util/Vec';

interface NodeInfo {
  nodeLoc: NodeLocation;
  props: AtomProps;
  template: TemplateDefn;
  rendNode: RenderingNode;
}

export interface ZoomingPreview {
  svg: string;
  width: number;
  height: number;
  theta: number;
}

export class ZoomingMonomers {
  private mapNodeInfo = new Map<number, NodeInfo>(); // atomidx-to-nodeinfo
  private mapPreview = new Map<number, ZoomingPreview>(); // atomto-to-results
  private policy = RenderPolicy.defaultBlackOnWhite(20);

  constructor(private render: RenderMoleculeSVG) {
  }

  public build(): void {
    const { v3000template, nodeLocations } = this.render;

    for (const nodeLoc of nodeLocations) {
      if (nodeLoc.nodeType != NodeLocationType.Monomer) continue;
      const props = v3000template.atomProps.get(nodeLoc.atomIndex);
      if (!props) continue;
      const template = v3000template.namedTemplates.get(`${props.templateClass}/${props.label}`);
      if (!template) continue;
      const rendNode = v3000template.renderingNodes.find((look) => look.aidx == nodeLoc.atomIndex);
      this.mapNodeInfo.set(nodeLoc.atomIndex, { nodeLoc, props, template, rendNode });
    }
  }

  public needsPreview(atomidx: number): boolean {
    return this.mapNodeInfo.has(atomidx);
  }

  public hasPreview(atomidx: number): boolean {
    return this.mapPreview.has(atomidx);
  }

  public getPreview(atomidx: number): ZoomingPreview {
    let preview = this.mapPreview.get(atomidx);
    if (preview) return preview;

    const nodeInfo = this.mapNodeInfo.get(atomidx);
    if (!nodeInfo) return null;

    preview = this.createAlignedPreview(nodeInfo);
    this.mapPreview.set(atomidx, preview);
    return preview;
  }

  private createAlignedPreview(nodeInfo: NodeInfo): ZoomingPreview {
    const { atomIndex } = nodeInfo.nodeLoc;
    const { effectiveMol } = this.render;
    let mol = nodeInfo.template.mol;
    const maskKeep = Vec.booleanArray(false, mol.numAtoms);
    let nbrNode = Vec.numberArray(0, mol.numAtoms);

    for (const sgroup of nodeInfo.template.sgroups) {
      if (sgroup.templateClass == 'LGRP' || !(sgroup.attachPoints?.length > 0)) continue;

      for (const aidx of sgroup.atoms) maskKeep[aidx - 1] = true;

      for (const attach of sgroup.attachPoints) {
        const connectTo = nodeInfo.props.attachOrder.find((look) => look.name == attach.name); // the adjacent node in primary graph
        const leavingGroup = nodeInfo.template.sgroups.find((look) => look.templateClass == 'LGRP' && look.atoms.includes(attach.lidx));

        if (connectTo) {
          maskKeep[attach.lidx - 1] = true;
          nbrNode[attach.lidx - 1] = connectTo.nbr;
        } else {
          for (const aidx of (leavingGroup?.atoms ?? [])) {
            maskKeep[aidx - 1] = mol.atomElement(aidx) != 'H';
          }
        }
      }
    }

    mol = MolUtil.subgraphMask(mol, maskKeep);
    mol.trashTransient();
    nbrNode = Vec.maskGet(nbrNode, maskKeep);

    let numInside = 0;
    const ax = [0], ay = [0], bx = [effectiveMol.atomX(atomIndex)], by = [effectiveMol.atomY(atomIndex)];
    for (let n = 1; n <= mol.numAtoms; n++) {
      const nbridx = nbrNode[n - 1];
      if (nbridx == 0) {
        numInside++;
        ax[0] += mol.atomX(n);
        ay[0] += mol.atomY(n);
      } else {
        ax.push(mol.atomX(n));
        ay.push(mol.atomY(n));
        bx.push(effectiveMol.atomX(nbridx));
        by.push(effectiveMol.atomY(nbridx));
      }
    }
    if (ax.length >= 2) {
      ax[0] /= numInside;
      ay[0] /= numInside;
      const tfm = GeomUtil.superimpose(ax, ay, bx, by);
      for (let n = 1; n <= mol.numAtoms; n++) {
        const [x, y] = GeomUtil.applyAffine(mol.atomX(n), mol.atomY(n), tfm);
        mol.setAtomPos(n, x, y);
      }
      if (GeomUtil.isAffineMirror(tfm)) {
        for (let n = 1; n <= mol.numBonds; n++) {
          if (mol.bondType(n) == Molecule.BONDTYPE_INCLINED) {
            mol.setBondType(n, Molecule.BONDTYPE_DECLINED);
          } else if (mol.bondType(n) == Molecule.BONDTYPE_DECLINED) {
            mol.setBondType(n, Molecule.BONDTYPE_INCLINED);
          }
        }
      }
    }

    const scale = this.policy.data.pointScale;
    const measure = new OutlineMeasurement(0, 0, scale);

    const effects = new RenderEffects();
    for (let n = 1; n <= mol.numAtoms; n++) {
      if (nbrNode[n - 1] > 0) {
        mol.setAtomElement(n, '');
        mol.setAtomHExplicit(n, 0);
        effects.hideAtoms.add(n);
      }
    }

    const layout = new ArrangeMolecule(mol, measure, this.policy, effects);
    layout.arrange();

    const gfx = new MetaVector();
    const draw = new DrawMolecule(layout, gfx);
    draw.draw();

    for (let n = 1; n <= mol.numAtoms; n++) {
      const nbr = nbrNode[n - 1];
      if (nbr == 0) continue;
      const nbrInfo = this.mapNodeInfo.get(nbr);
      if (!nbrInfo) continue;

      const pt = layout.getPoint(n - 1);
      const { color, annotation } = nbrInfo.rendNode;
      const rad = 0.4 * scale, thick = 0.05 * scale;
      gfx.drawOval(pt.oval.cx, pt.oval.cy, rad, rad, 0x000000, thick, color);
      let fsz = 0.4 * scale;
      const maxW = 1.6 * rad;
      const wad = FontData.measureText(annotation, fsz);
      if (wad[0] > maxW) fsz *= maxW / wad[0];
      gfx.drawText(pt.oval.cx, pt.oval.cy, annotation, fsz, 0x000000, TextAlign.Centre | TextAlign.Middle);
    }

    gfx.normalise();

    return {
      svg: gfx.createSVG(),
      width: gfx.width,
      height: gfx.height,
      theta: this.calculateDirection(atomIndex),
    };
  }

  private calculateDirection(atomIndex: number): number {
    const { effectiveMol: mol } = this.render;

    const xlist: number[] = [], ylist: number[] = [];
    for (let n = 1; n <= mol.numAtoms; n++) {
      if (mol.atomConnComp(n) == mol.atomConnComp(atomIndex)) {
        xlist.push(mol.atomX(n));
        ylist.push(mol.atomY(n));
      }
    }

    if (xlist.length == 0) return 0;

    if (Vec.max(ylist) - Vec.min(ylist) < 0.5) {
      return -0.5 * Math.PI;
    }

    if (Vec.max(xlist) - Vec.min(xlist) < 0.5) {
      return 0;
    }

    const dx = mol.atomX(atomIndex) - Vec.sum(xlist) / xlist.length;
    const dy = mol.atomY(atomIndex) - Vec.sum(ylist) / ylist.length;
    return Math.atan2(dy, dx);
  }
}
